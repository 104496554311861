import { FC, useMemo } from 'react';
import { Spinner } from 'react-activity';
import { P, match } from 'ts-pattern';
import { SquareMinorButton } from '~src/components/button';
import { DisplayP } from '~src/components/text';
import { BrandStrong } from '~src/components/text/spans';
import { useFarcasterReactionMutation } from '~src/external/farcaster/hooks/useFarcasterReaction';
import { HiraethInTimeCheckoutProvider } from '~src/pages/hiraeth/txn/providers/checkout/HiraethInTimeCheckout';
import { serializeBlockverseObjId } from '~src/shared-apps/blockverse/utils/serialize';
import { CheckoutDialogs } from '~src/shared-apps/checkout/modals';
import type { CheckoutItemStateProps } from '~src/shared-apps/checkout/types/state';
import { css } from '~src/styled-system/css';
import { Box, Flex } from '~src/styled-system/jsx';
import { token } from '~src/styled-system/tokens';
import { useDeletePostMutation } from '../../hooks/post/useDeletePost';
import { useBlockverseSocialFeed } from '../../providers/BlockverseSocialFeed';
import type { SocialPostForUI } from '../../types/post/ui';
import { SocialPostCard, type SocialPostCardProps } from '../post';

export type BlockverseSocialFeedProps = {};

// const LazyHiraethInTimeCheckoutContext = dynamic(
//   () =>
//     import('~src/pages/hiraeth/txn/providers/checkout/HiraethInTimeCheckout').then(
//       (mod) => mod.HiraethInTimeCheckoutContext,
//     ),
//   { ssr: false },
// );

export const BlockverseSocialFeed: FC<BlockverseSocialFeedProps> = ({}) => {
  const { blockverseFeedResult, hiraethCheckoutStates } =
    useBlockverseSocialFeed();

  const { mutate: reactToPost } = useFarcasterReactionMutation();
  const {
    mutate: deletePost,
    variables: deletePostVariables,
    isPending: isDeleting,
  } = useDeletePostMutation();

  const posts = useMemo(() => {
    return (
      blockverseFeedResult?.data?.pages
        .flatMap((page) => page?.posts)
        .filter((p): p is SocialPostForUI => !!p) ?? []
    );
  }, [blockverseFeedResult]);

  return (
    <HiraethInTimeCheckoutProvider mintStateLiveness={'fresh'}>
      <CheckoutDialogs />
      <Box>
        {match(blockverseFeedResult)
          .with(P.nullish, () => null)
          .with(
            {
              isPending: true,
            },
            () => {
              return (
                <Box
                  borderBottom={'1px solid'}
                  borderColor={'gray.100'}
                  bg={'darken.025'}
                  flx={'flexCenter'}
                  py={'twoExLargestBox'}
                >
                  <Spinner color={token.var('colors.gray.400')} />
                </Box>
              );
            },
          )
          .with(
            {
              isError: true,
            },
            () => {
              return (
                <Box
                  borderBottom={'1px solid'}
                  borderColor={'gray.100'}
                  bg={'darken.025'}
                  flx={'flexCenter'}
                  py={'twoExLargestBox'}
                >
                  <DisplayP.Caption color={'gray.400'}>
                    Error fetching feed. Ping{' '}
                    <BrandStrong>Pob Studio</BrandStrong> for help.
                  </DisplayP.Caption>
                </Box>
              );
            },
          )
          .with({ data: P.nullish }, () => null)
          .otherwise(({ data }) => {
            return (
              <>
                <Box
                  display={'grid'}
                  gap={'thinGap'}
                  rounded={'innerBox'}
                  overflow={'hidden'}
                  shadow={'soft'}
                >
                  {posts.map((post) => {
                    let state: SocialPostCardProps['state'] = 'posted';

                    // if (isDeleting && deletePostVariables?.post.id === post.id) {
                    //   state = 'deleting';
                    // }
                    let checkoutState: CheckoutItemStateProps | undefined;
                    if (post.blockverse?.id) {
                      const serializedObjId = serializeBlockverseObjId(
                        post.blockverse.id,
                      );
                      checkoutState = hiraethCheckoutStates[serializedObjId];
                    }

                    return (
                      <SocialPostCard
                        slots={{
                          container: socialPostCardContainerCss as any,
                        }}
                        key={post.id}
                        post={post}
                        state={state}
                        onLike={async () =>
                          reactToPost({ post, reactionType: 'like' })
                        }
                        onRepost={undefined}
                        repostHref={post.externalUrl?.url}
                        repostTarget={'_blank'}
                        replyHref={post.externalUrl?.url}
                        replyTarget={'_blank'}
                        onReply={undefined}
                        onBookmark={undefined}
                        onDelete={async () => deletePost({ post })}
                        showBlockverseFrame={true}
                        checkoutState={checkoutState}
                      />
                    );
                  })}
                </Box>
                {match(blockverseFeedResult)
                  .with(
                    {
                      hasNextPage: false,
                    },
                    () => {
                      return null;
                    },
                  )
                  .with(
                    {
                      isFetchingNextPage: true,
                    },
                    () => {
                      return (
                        <Flex flx={'flexCenter'} mt={'largeBox'}>
                          <Spinner
                            color={token.var('colors.gray.400')}
                            size={10}
                          />
                        </Flex>
                      );
                    },
                  )
                  .otherwise(() => {
                    return (
                      <Flex flx={'flexCenter'} mt={'largeBox'}>
                        <SquareMinorButton
                          btnType={'quaternarySolid'}
                          color={'gray.700'}
                          onClick={() => {
                            blockverseFeedResult?.fetchNextPage();
                          }}
                        >
                          Load more
                        </SquareMinorButton>
                      </Flex>
                    );
                  })}
              </>
            );
          })}
      </Box>
    </HiraethInTimeCheckoutProvider>
  );
};

const socialPostCardContainerCss = css.raw({
  rounded: 'innerBox',
  bg: 'white',
});
