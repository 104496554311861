import { FC, ReactNode } from 'react';
import { ContentSpacer, styled } from '~src/styled-system/jsx';
import { route } from '~src/utils/routes';
import { DISCORD_LINK, NEWSLETTER_LINK, TWITTER_LINK } from '../../constants';
import { CtaA } from '../anchor';
import { WidthContainer, type WidthContainerSize } from '../layout';
import { H4 } from '../text';
import { BrandStrong } from '../text/spans';

const BlockContainer = styled('div', {
  base: { pos: 'relative' },
});

const CtaH1 = styled('h1', {
  base: {
    color: 'gray.600',
    textAlign: 'center',
    fontFamily: 'display',
    fontWeight: 'display.superBold',
    fontSize: {
      base: 'h1.mobile',
      md: 'h1',
    },
  },
});

const StyledMultilineDisplayP = styled('p', {
  base: {
    color: 'gray.600',
    textAlign: 'center',
    textStyle: 'p.display.multi',
  },
});

const ButtonRow = styled('div', {
  base: {
    display: 'flex',
    alignItems: 'center',
    gap: 'increment',
    justifyContent: 'center',
  },
});

const BlockContent = styled('div', {
  base: {
    pos: 'relative',
    rounded: 'box',
    // bg: 'whitePane',
    bgGradient: 'whiteFadeUpToBottom',
    pt: {
      base: 'largestBox',
      sm: 'twoExLargestBox',
    },
    pb: {
      base: 'largestBox',
      sm: 'largestBox',
    },
    px: {
      base: 'box',
      sm: 'largestBox',
    },
  },
});

export const CtaBlock: FC<{
  children?: ReactNode;
  width?: WidthContainerSize;
}> = ({ children, width = 'md' }) => {
  return (
    <BlockContainer>
      <WidthContainer width={width}>
        {children ?? <SocialCtaContent />}
      </WidthContainer>
    </BlockContainer>
  );
};

export interface MarketplaceLink {
  href: string;
  target?: string;
  label: string;
  isPrimary?: boolean;
}

export const GenericCtaContent: FC<{
  title?: ReactNode;
  projectName?: string;
  description?: ReactNode;
  links: MarketplaceLink[];
}> = ({ description, title, links, projectName }) => {
  return (
    <BlockContent>
      <H4
        color={'gray.400'}
        textAlign={'center'}
        style={{ transform: 'rotate(90deg)' }}
      >
        ❥
      </H4>
      <CtaH1>{title ?? <>Buy {projectName} from Secondary</>}</CtaH1>
      <StyledMultilineDisplayP>
        {description ?? (
          <>
            Collect and enjoy {projectName}. Proudly made by{' '}
            <BrandStrong>Pob Studio</BrandStrong>.
          </>
        )}
      </StyledMultilineDisplayP>
      <ContentSpacer size={'box'} isMobile />
      <ButtonRow>
        {links.map((l, i) => {
          return (
            <CtaA
              href={l.href}
              target={l.target}
              btnType={l.isPrimary ? 'primary' : 'secondary'}
              key={`purchase-cta-content-link-${i}`}
            >
              {l.label}
            </CtaA>
          );
        })}
      </ButtonRow>
    </BlockContent>
  );
};

export const WorksCta: FC<{
  title?: ReactNode;

  width: WidthContainerSize;
}> = ({ title, width }) => {
  return (
    <CtaBlock width={width}>
      <GenericCtaContent
        title={title ?? 'Learn & Purchase Our Works'}
        description={
          <>
            Stay up to date and experience the latest from{' '}
            <BrandStrong>Pob Studio</BrandStrong>
          </>
        }
        links={[
          {
            href: route({
              pathname: '/work',
            }),
            label: 'Explore Our Works',
            isPrimary: true,
          },
          {
            href: TWITTER_LINK,
            label: 'Follow Twitter',
            target: '_blank',
          },
        ]}
      />
    </CtaBlock>
  );
};

export const SocialCtaContent: FC = () => {
  return (
    <BlockContent>
      <H4
        color={'gray.400'}
        textAlign={'center'}
        pointerEvents={'none'}
        transform={'[rotate(90deg)]'}
      >
        ❥
      </H4>
      <CtaH1>Stay Informed</CtaH1>
      <StyledMultilineDisplayP>
        Keep up to date with the latest from{' '}
        <BrandStrong>Pob Studio</BrandStrong>
      </StyledMultilineDisplayP>
      <ContentSpacer size={'largestBox'} isMobile />
      <ButtonRow>
        <CtaA href={NEWSLETTER_LINK} target={'_blank'} btnType="primary">
          Newsletter
        </CtaA>
        <CtaA href={DISCORD_LINK} target={'_blank'} btnType="secondary">
          Discord
        </CtaA>
      </ButtonRow>
    </BlockContent>
  );
};
