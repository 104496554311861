import type { Evm } from '@pob/shared';
import { bitcoin, type AvailableChainId } from '@protocol/chains';
import Link from 'next/link';
import { FC, useMemo, useState } from 'react';
import { base, lukso, mainnet, optimism } from 'viem/chains';
import { trpc } from '~src/clients/trpc-client';
import { ActionA } from '~src/components/anchor';
import { ActionButton, MinorButton } from '~src/components/button';
import { ICONS_BY_CHAIN_IDS } from '~src/components/chains/icons';
import { SPANS_BY_CHAIN_ID } from '~src/components/chains/spans';
import { LineBreak } from '~src/components/decoration/lineBreak';
import { UpRightArrowIcon } from '~src/components/icon/arrows';
import { Container, Spacer } from '~src/components/layout';
import { H5 } from '~src/components/text';
import { InheritSpan, Strong, SuperStrong } from '~src/components/text/spans';
import { Tooltip, tooltipTimingProps } from '~src/components/tooltip';
import { QUERY_LIVENESS } from '~src/constants/query';
import type { FarcasterCastHash } from '~src/external/farcaster/types';
import { useCmdContext } from '~src/shared-apps/cmd/providers/Cmd';
import { SocialPostCard } from '~src/shared-apps/social/components/post';
import type { SocialPostForUI } from '~src/shared-apps/social/types/post/ui';
import { css } from '~src/styled-system/css';
import { Box, Circle, Flex, VStack, styled } from '~src/styled-system/jsx';

export const Hero: FC = () => {
  return (
    <Box pos={'relative'}>
      <Box
        abs={'cover'}
        opacity={'30'}
        pointerEvents={'none'}
        backgroundGradient={
          '[radial-gradient(at 27% 47%, {colors.lavender} 0, transparent 50%), radial-gradient(at 97% 21%, {colors.lavender} 0, transparent 50%), radial-gradient(at 52% 50%, {colors.blockverse} 0, transparent 50%), radial-gradient(at 10% 29%, {colors.white} 0, transparent 50%), radial-gradient(at 50% 96%, {colors.blockverse} 0, transparent 70%), radial-gradient(at 33% 50%, {colors.turquoise} 0, transparent 50%), radial-gradient(at 79% 53%, {colors.blockverse} 0, transparent 50%);]'
        }
      />
      <BlockverseHeader />
      <LineBreak />
      {/* <BlockverseGallery /> */}
    </Box>
  );
};

type BlockverseHeaderHistoryProps = {
  type: string;
  chainId: AvailableChainId;
  label: string;
  comingSoon?: boolean;
  hash?: FarcasterCastHash;
  post: SocialPostForUI | null;
};

const HISTORY_ITEMS = [
  {
    type: 'first-txn',
    chainId: mainnet.id,
    label: 'First Txn on Eth',
    comingSoon: false,
    hash: '0x199e108d1cd2f084a4ac2b518c23816b2cf2ca85' as FarcasterCastHash,
    post: {
      id: '123357e2-59d5-496c-aceb-a39656985dff',
      author: {
        handle: 'remembrancer',
        name: 'Remembrancer',
        profileUrl: 'https://warpcast.com/remembrancer',
        profileImageSrc:
          'https://imagedelivery.net/BXluQx4ige9GuW0Ia56BHw/ce4eda72-0ce9-4646-6ca3-6623d34f1000/rectcrop3',
      },
      channel: {
        id: 'crypto-history',
      },
      html: '<p>The very first txn on Ethereum\n\n</p>',
      embeds: [],
      mentions: [],
      timestamp: new Date('2024-12-23T19:41:53.000Z'),
      externalUrl: {
        url: 'https://warpcast.com/remembrancer/0x199e108d',
        label: 'Warpcast',
      },
      externalArchivedUrl: null,
      reactions: {
        likeCount: 0,
        replyCount: 0,
        repostCount: 0,
      },
      platform: {
        type: 'farcaster',
        id: '0x199e108d1cd2f084a4ac2b518c23816b2cf2ca85',
        authorId: '883671',
      },
      blockverse: {
        id: [
          'hiraeth',
          1,
          '0x5c504ed432cb51138bcf09aa5e8a410dd4a1e204ef84bfed1be16dfba1b22060' as Evm.TxnHash,
        ],
      },
      viewer: null,
    },
  },
  {
    type: 'jesse-first-txn',
    chainId: base.id,
    label: 'Jesse First Txn',
    comingSoon: false,
    hash: '0x4a5d164e99a207eade561544f7a58d17bd0242ec' as FarcasterCastHash,
    post: {
      id: '3f505e14-6fc0-4bd3-8bd2-d5da4b7672ad',
      author: {
        handle: 'dave4506',
        name: 'David ☀️',
        profileUrl: 'https://warpcast.com/dave4506',
        profileImageSrc:
          'https://imagedelivery.net/BXluQx4ige9GuW0Ia56BHw/df7657e7-14f5-4391-34dd-4ea3cdef7000/rectcrop3',
      },
      channel: { id: 'crypto-history' },
      html: '<p><a class="mention" href="https://warpcast.com/jessepollak" target="_blank" >@jessepollak</a>\'s first NFT mint and his first mint on <a href="jesse.base.eth" target="_blank">jesse.base.eth</a>!\n\n</p>',
      embeds: [],
      mentions: [
        {
          handle: 'jessepollak',
          profileUrl: 'https://warpcast.com/jessepollak',
        },
      ],
      timestamp: new Date('2024-12-23T19:22:28.000Z'),
      externalUrl: {
        url: 'https://warpcast.com/dave4506/0x4a5d164e',
        label: 'Warpcast',
      },
      externalArchivedUrl: null,
      reactions: { likeCount: 0, replyCount: 0, repostCount: 0 },
      platform: {
        type: 'farcaster',
        id: '0x4a5d164e99a207eade561544f7a58d17bd0242ec',
        authorId: '298311',
      },
      blockverse: {
        id: [
          'hiraeth',
          8453,
          '0x41ce32da4af294f49928bc4b75cbd07cab7c7c9107c2bf960ee824534a4a0ab2' as Evm.TxnHash,
        ],
      },
      viewer: null,
    },
  },
  {
    type: 'op-token-deployment',
    chainId: optimism.id,
    label: '$OP Token Deployment',
    hash: '0x36ca0b75eef3fe56714f872193b790b2dc84d0e3' as FarcasterCastHash,
    comingSoon: false,
    post: {
      id: '7f38d72c-2016-4e91-a10e-2c1e0edbf733',
      author: {
        handle: 'pobstudio',
        name: 'Pob Studio',
        profileUrl: 'https://warpcast.com/pobstudio',
        profileImageSrc:
          'https://imagedelivery.net/BXluQx4ige9GuW0Ia56BHw/19afaaa0-c480-4bdc-3b90-c44c3dae8200/rectcrop3',
      },
      channel: { id: 'crypto-history' },
      html: '<p>The very transaction that created $OP token, forever enshrined into a NFT.\n\n</p>',
      embeds: [],
      mentions: [],
      timestamp: new Date('2024-10-16T17:59:21.000Z'),
      externalUrl: {
        url: 'https://warpcast.com/pobstudio/0x36ca0b75',
        label: 'Warpcast',
      },
      externalArchivedUrl: null,
      reactions: { likeCount: 2, replyCount: 0, repostCount: 2 },
      platform: {
        type: 'farcaster',
        id: '0x36ca0b75eef3fe56714f872193b790b2dc84d0e3',
        authorId: '549185',
      },
      blockverse: {
        id: [
          'hiraeth',
          10,
          '0x27cbaa7570dff45d16316353595ebd99b4b68ae6571fda7f0f1dab2d0ada543d' as Evm.TxnHash,
        ],
      },
      viewer: null,
    },
  },
  {
    type: 'bitcoin-pizza-txn',
    chainId: bitcoin.id,
    label: 'Bitcoin Pizza Txn',
    comingSoon: true,
    hash: '0x36ca0b75eef3fe56714f872193b790b2dc84d0e3' as FarcasterCastHash,
    post: null,
  },
] as const satisfies BlockverseHeaderHistoryProps[];

type BlockverseHeaderHistoryType = (typeof HISTORY_ITEMS)[number]['type'];

const BlockverseHeader: FC = () => {
  return (
    <Container.Sm
    // paddingX={{
    //   base: 'innerBox',
    //   xl: 'innerBox',
    // }}
    >
      <Spacer.LargestBox isMobile />
      <Box
        display={'flex'}
        flexDirection={{
          base: 'column',
          // sm: 'row',
        }}
        alignItems={'center'}
        // justifyContent={'space-between'}
        // textAlign={{
        //   base: 'center',
        //   sm: 'left',
        // }}
        // gap={'box'}
      >
        {/* <DisplayP.Default color={'gray.600'} opacity={'50'} lineHeight={'1'}>
          <Em>
            {' '}
            Welcome to the{' '}
            <InheritSpan fontWeight={'display.superBold'} color={'gray.600'}>
              Blockverse
            </InheritSpan>
          </Em>
        </DisplayP.Default> */}
        <AnnouncementContent />
        <Spacer.Box isMobile />
        <HeaderTitle
          textAlign={'center'}
          mt={'increment'}
          // animation={'subtle-slide-in'}
          // animationDelay={'0.3s'}
          // animationFillMode={'both'}
        >
          <SuperStrong>One of one</SuperStrong> NFTs that represent{' '}
          <styled.br hideFrom={'sm'} /> your transactions.
        </HeaderTitle>
        <H5
          fontSize={'h4-5'}
          pt={'increment'}
          fontWeight={'display.bold'}
          color={'gray.800'}
          // animation={'subtle-slide-in'}
          // animationDelay={'0.5s'}
          // animationFillMode={'both'}
        >
          Don't just read about history. Own a part of it.
        </H5>
        <Spacer.Box isMobile />
        <HeroButton />
        <Spacer.LargeBox isMobile />
        <LineBreak
          backgroundGradient={'gray200HorzPart'}
          // animation={'subtle-slide-in'}
          // animationDelay={'0.8s'}
          // animationFillMode={'both'}
        />
        <Spacer.LargeBox isMobile />
        <HeaderHistorySelector />
        {/* <Notification /> */}
      </Box>
      <Spacer.OnePointFiveExLargestBox isMobile />
    </Container.Sm>
  );
};

const HeroButton: FC<{}> = ({}) => {
  const { open } = useCmdContext();
  return (
    <ActionButton
      onClick={() => {
        open();
      }}
      // animation={'subtle-slide-in'}
      // animationDelay={'0.6s'}
      // animationFillMode={'both'}
      btnType={'secondary'}
      bg={'background'}
      shadow={'strong'}
      borderColor={'lavender/60'}
      color={'black'}
      display={'flex'}
      alignItems={'center'}
      pos={'relative'}
      gap={'halfIncrement'}
    >
      <Box
        abs={'cover'}
        opacity={'50'}
        top={'-box'}
        bottom={'-box'}
        backgroundGradient={
          '[radial-gradient(at 27% 47%, {colors.lavender} 0, transparent 50%), radial-gradient(at 97% 21%, {colors.lavender} 0, transparent 50%), radial-gradient(at 52% 50%, {colors.blockverse} 0, transparent 50%), radial-gradient(at 10% 29%, {colors.white} 0, transparent 50%), radial-gradient(at 50% 96%, {colors.blockverse} 0, transparent 70%), radial-gradient(at 33% 50%, {colors.turquoise} 0, transparent 50%), radial-gradient(at 79% 53%, {colors.blockverse} 0, transparent 50%);]'
        }
      />

      <InheritSpan pos={'relative'} fontWeight={'display.bold'}>
        Mint crypto history
      </InheritSpan>
      <UpRightArrowIcon
        className={css({
          width: 'icon.sixteen',
          height: 'icon.sixteen',
        })}
      />
    </ActionButton>
  );
};

const AnnouncementContent: FC = () => {
  return (
    <Box>
      {/* <VStack alignItems={'center'}>
        <HeaderTitle>
          <SuperStrong>One of one</SuperStrong> NFTs that represent{' '}
          <styled.br hideFrom={'sm'} /> your transactions.
        </HeaderTitle>
      </VStack> */}
      <VStack
        // zIndex={'nav'}
        // pos={'sticky'}
        // top={'[calc({sizes.nav} + {spacing.increment})]'}
        alignItems={'center'}
        pos={'relative'}
      >
        <Link href={'/blog/lukso-hiraeth'} passHref legacyBehavior>
          <ActionA
            target="_blank"
            shadow={'default'}
            py={'increment'}
            pl={'innerBox'}
            display={'flex'}
            alignItems={'center'}
            rounded={'squaredButton'}
            gap={'increment'}
            pr={'increment'}
            bg={'white'}
            border={'1px solid'}
            borderColor={'gray.200'}
            color={'gray.900'}
            tran={'default'}
            transitionProperty={'transform'}
            _hover={{
              transform: '[scale(0.96)]',
            }}
          >
            <Circle
              size={'icon.twentyFour'}
              bg={'lukso'}
              pos={'relative'}
              overflow={'hidden'}
            >
              {ICONS_BY_CHAIN_IDS[lukso.id]}
            </Circle>
            <Box textAlign={'left'} lineHeight={'1'}>
              <Box>
                <Strong fontWeight={'display.superBold'}>
                  {SPANS_BY_CHAIN_ID[lukso.id]}
                </Strong>{' '}
              </Box>
              <Box>history is live!</Box>
            </Box>
            <Box
              rounded={'innerOfSquaredButton'}
              bg={'lukso'}
              color={'white'}
              px={'innerBox'}
              py={'increment'}
            >
              Announcement
            </Box>
          </ActionA>
        </Link>
      </VStack>
    </Box>
  );
};

const HeaderTitle = styled('h2', {
  base: {
    fontWeight: 'display.normal',
    fontSize: {
      base: '[2.2rem]',
      sm: '[2.4rem]',
    },
    lineHeight: '1',
  },
});

const HeaderHistorySelector: FC = () => {
  const [selectedItem, setSelectedItem] = useState<BlockverseHeaderHistoryType>(
    'op-token-deployment',
  );
  return (
    <>
      <Flex
        // animation={'subtle-slide-in'}
        // animationDelay={'0.8s'}
        // animationFillMode={'both'}
        gap={'innerBox'}
        alignItems={'center'}
        flexWrap={'wrap'}
        justifyContent={{
          base: 'center',
          sm: 'flex-start',
        }}
      >
        {HISTORY_ITEMS.map((item) => (
          <Tooltip.Root
            key={item.type}
            disabled={!item.comingSoon}
            positioning={{
              placement: 'top',
            }}
            {...tooltipTimingProps.default}
          >
            <Tooltip.Trigger asChild>
              <MinorButton
                whiteSpace={'nowrap'}
                display={'flex'}
                alignItems={'center'}
                gap={'increment'}
                py={'increment'}
                // bg={'darken.025'}
                tran={'default'}
                transitionProperty={'background'}
                // _hover={{
                //   bg: 'darken.025',
                // }}
                disabled={item.comingSoon}
                color={'gray.600'}
                fontWeight={'display.normal'}
                _selected={{
                  bg: 'darken.025',
                }}
                {...(selectedItem === item.type
                  ? {
                      ['data-selected']: true,
                    }
                  : {})}
                onClick={() => {
                  setSelectedItem(item.type);
                }}
              >
                <Circle
                  size={'icon.sixteen'}
                  bg={'darken.05'}
                  pos={'relative'}
                  overflow={'hidden'}
                >
                  {ICONS_BY_CHAIN_IDS[item.chainId]}
                </Circle>
                {item.label}
              </MinorButton>
            </Tooltip.Trigger>
            <Tooltip.Positioner>
              <Tooltip.Content style={{ width: 220 }}>
                <Tooltip.Box>
                  <Tooltip.P>{item.comingSoon ? 'Coming Soon' : ''}</Tooltip.P>
                </Tooltip.Box>
              </Tooltip.Content>
            </Tooltip.Positioner>
          </Tooltip.Root>
        ))}
      </Flex>
      <Spacer.MediumBox />
      <Container.Sm
        width={'sm'}
        // minWidth={{
        //   base: 'initial',
        //   md: 'sm',
        // }}
        // animation={'subtle-slide-in'}
        // animationDelay={'1s'}
        // animationFillMode={'both'}
        padMobile
      >
        <HeaderPost type={selectedItem} />
      </Container.Sm>
    </>
  );
};

const HeaderPost: FC<{
  type: BlockverseHeaderHistoryType;
}> = ({ type }) => {
  const item = useMemo(() => {
    return HISTORY_ITEMS.find((i) => i.type === type)!;
  }, [type]);

  const postRes = trpc.social.post.getOne.useQuery(
    {
      hash: item.hash,
    },
    {
      ...QUERY_LIVENESS.constant,
      initialData: !!item.post
        ? {
            post: item.post,
          }
        : undefined,
    },
  );

  if (!postRes.data?.post) {
    return null;
  }

  return (
    <SocialPostCard
      post={postRes.data?.post}
      state={'posted'}
      onBookmark={undefined}
      onDelete={undefined}
      onLike={undefined}
      onReply={undefined}
      replyHref={undefined}
      replyTarget={undefined}
      onRepost={undefined}
      repostHref={undefined}
      repostTarget={undefined}
      showBlockverseFrame={true}
      customActionRow={null}
      imageProps={{
        defaultLightBoxRendering: 'height-constrained',
      }}
      slots={{
        actionRow: {
          display: 'none',
        },
        container: {
          display: 'flex',
          flexDirection: 'column-reverse',
          alignItems: 'stretch',
          shadow: 'default',
        },
        blockverseFrameContainer: {
          mt: '-innerBox',
          mb: 'box',
        },
      }}
    />
  );
};
// const Notification: FC = () => {
//   return (
//     <>
//         <NotificationContainer as={'a'}>
//           {/* <NotificationDot /> */}

//           <NotificationChainImgContainer>
//             {ICONS_BY_CHAIN_NETWORKS[base.id]}
//           </NotificationChainImgContainer>
//           <DisplayP style={{ fontSize: '0.85rem', lineHeight: '0.95rem' }} color={'white'}>
//             <Strong style={{ marginRight: 4 }}>Have a base.eth domain?</Strong><br/>
//             <InheritSpan>Mint NFTs representing your history.</InheritSpan>
//           </DisplayP>
//           <ChevronRightIcon/>
//         </NotificationContainer>
//       </Link>
//     </>
//   );
// };

// const NotificationChainImgContainer = styled.div`
//   border-radius: ${(p) => p.theme.pillBorderRadius}px;
//   overflow: hidden;
//   height: 36px;
//   width: 36px;
//   position: relative;
// `;

// const NotificationContainer = styled.div`
//   text-decoration: none;
//   padding: ${(p) => p.theme.padding.increment}px ${(p) => p.theme.padding.increment}px;
//   border: 0px solid ${({ theme }) => theme.gray.200};
//   /* background-color: ${(p) => p.theme.gray.200}; */
//   color: ${({ theme }) => theme.white};
//   ${DISPLAY_FONT_FAMILY_STYLE};
//   background-color: ${COINBASE_BLUE};
//   font-size: 0.9rem;
//   display: flex;
//   align-items: center;
//   width: fit-content;
//   border-radius: ${(p) => p.theme.squaredButtonBorderRadius}px;
//   transition: ${DEFAULT_TRANSITION_STYLES('all')};
//   ${SOFT_HOVER_STYLES};
//   overflow: hidden;
//   position: relative;
//   display: flex;
//   align-items: center;
//   gap: ${(p) => p.theme.padding.innerBox}px;
//   ${p => SVG_ONLY_FILL_COLOR(p.theme.white)};
//   @media (max-width:${(p) => p.theme.breakpoints.upToSmall}px) {
//     text-align: left;
//   }
// `;

// const NotificationDot = styled.div`
//   height: 10px;
//   width: 10px;
//   background-color: ${({ theme }) => theme.gray.600};
//   ${opacityBreathAnimation};
//   border-radius: 50%;
//   margin-right: 8px;
// `;

// const GALLERY_ITEMS: {
//   chainId: AvailableChainId;
//   label: string;
//   moment: string;
// }[] = [
//   {
//     txnHash:
//       '0x5c504ed432cb51138bcf09aa5e8a410dd4a1e204ef84bfed1be16dfba1b22060',
//     chainId: mainnet.id,
//     label: 'First Txn on Eth',
//     moment: 'Historic Moments',
//   },
//   {
//     txnHash:
//       '0x4f9052feb5b3b20f79fabc175438824badebcf840e478f36b0f3731ae524e14b',
//     chainId: base.id,
//     label: 'Base Paint Deployment',
//     moment: 'Cultural Moments',
//   },
//   {
//     txnHash:
//       '0xbdab447ba2fd0a493d93635da202ebcfaa309bcc6a22a95d808c93ce8f1c6c2d',
//     chainId: mainnet.id,
//     label: 'ZRX Token Deployment',
//     moment: 'Historic Moments',
//   },
//   {
//     txnHash:
//       '0x78a90a565cdf019bcb7fca7ae4f713a6acc16fa4b35b61d620fc00f9cae40514',
//     chainId: base.id,
//     label: "Studio's 1st Txn on Base",
//     moment: 'Personal Moments',
//   },
// ];

// const BlockverseGallery: FC = () => {
//   const blockverseChainIds = useBlockverseChainIds();
//   const [galleryItemIndex, setGalleryItemIndex] = useState(0);

//   useInterval(() => {
//     setGalleryItemIndex((t) => (t + 1) % GALLERY_ITEMS.length);
//   }, 6000);

//   const galleryItemKey = useMemo(
//     () =>
//       `${GALLERY_ITEMS[galleryItemIndex].chainId}-${GALLERY_ITEMS[galleryItemIndex].txnHash}`,
//     [galleryItemIndex],
//   );

//   const { openModal } = useSearchModalContext();

//   return (
//     <BlockverseGalleryContainer>
//       <BlockverseGalleryLayout $isMobilePadded>
//         <Link
//             CHAIN_ID_TO_NETWORK_LOOKUP[GALLERY_ITEMS[galleryItemIndex].chainId],
//             GALLERY_ITEMS[galleryItemIndex].txnHash,
//           )}
//           passHref
//           legacyBehavior
//         >
//           <BlockverseGalleryWell as={'a'}>
//             <BlockverseGalleryIndicatorsContainer>
//               {GALLERY_ITEMS.map((_, i) => (
//                 <GalleryIndicatorDotButton
//                   onClick={(e) => {
//                     e.preventDefault();
//                     setGalleryItemIndex(i);
//                   }}
//                   key={`blockverse-gallery-indicator-${i}`}
//                 >
//                   <GalleryIndicatorDot $active={galleryItemIndex === i} />
//                 </GalleryIndicatorDotButton>
//               ))}
//             </BlockverseGalleryIndicatorsContainer>
//             <BlockverseCarouselCardContainer>
//               <LightBox contentRatio={HIRAETH_ART_RATIO}>
//                 <AnimatePresence initial={false} mode={'wait'}>
//                   <BlockverseCarouselCard
//                     key={`blockverse-gallery-item-${galleryItemKey}`}
//                     {...GALLERY_ITEMS[galleryItemIndex]}
//                   />
//                 </AnimatePresence>
//               </LightBox>
//             </BlockverseCarouselCardContainer>
//           </BlockverseGalleryWell>
//         </Link>
//         <BlockverseTextContentColumn>
//           <Link
//               CHAIN_ID_TO_NETWORK_LOOKUP[
//                 GALLERY_ITEMS[galleryItemIndex].chainId
//               ],
//               GALLERY_ITEMS[galleryItemIndex].txnHash,
//             )}
//             passHref
//             legacyBehavior
//           >
//             <FeedItemDetailsContainer as={'a'}>
//               <BlockverseMomentLabel as={'div'} style={{ marginBottom: 4 }}>
//                 <ReplaceSpan
//                   textAlign="center"
//                   fixedWidth={360}
//                   content={{
//                     key: `blockverse-moment-${galleryItemIndex}`,
//                     node: GALLERY_ITEMS[galleryItemIndex].moment,
//                   }}
//                 />

//                 <DisplayP
//                   color={'gray.600'}
//                   style={{ marginBottom: 4 }}
//                 ></DisplayP>
//               </BlockverseMomentLabel>
//               <BlockverseName as={'div'}>
//                 <ReplaceSpan
//                   textAlign="center"
//                   fixedWidth={360}
//                   content={{
//                     key: `blockverse-name-${galleryItemIndex}`,
//                     node: GALLERY_ITEMS[galleryItemIndex].label,
//                   }}
//                 />
//               </BlockverseName>
//               <IncrementContentSpacer />
//               <BlockverseIdLabel as={'div'}>
//                 <ReplaceSpan
//                   textAlign="center"
//                   fixedWidth={120}
//                   content={{
//                     key: `blockverse-txn-${galleryItemIndex}`,
//                     node: getShortenedTxn(
//                       GALLERY_ITEMS[galleryItemIndex].txnHash,
//                     ),
//                   }}
//                 />
//               </BlockverseIdLabel>
//             </FeedItemDetailsContainer>
//           </Link>
//           <ExtraSmallContentSpacer />
//           <CtaButton onClick={openModal}>
//             Blockverse Awaits
//           </CtaButton>
//           <ExtraSmallContentSpacer />
//           <DisplayMedP color={'gray.400'}>Available on</DisplayMedP>
//           <IncrementContentSpacer />
//           <ChainsContainer>
//             {blockverseChainIds.map((chainId) => (
//               <Flex style={{ gap: 6 }} key={`blockverse-chain-label-${chainId}`}>
//                 <ChainImgContainer>
//                   {ICONS_BY_CHAIN_NETWORKS[chainId]}
//                 </ChainImgContainer>
//                 <DisplayMedP>{SPANS_BY_CHAIN_NETWORKS[chainId]}</DisplayMedP>
//               </Flex>
//             ))}
//           </ChainsContainer>
//         </BlockverseTextContentColumn>
//       </BlockverseGalleryLayout>
//     </BlockverseGalleryContainer>
//   );
// };

// const BlockverseTextContentColumn = styled(FlexCenterColumn)`
//   justify-content: center;
//   @media (max-width: ${(p) => p.theme.breakpoints.upToSmall}px) {
//     padding-top: ${(p) => p.theme.padding.page}px;
//   }
// `;
// const GalleryIndicatorDot = styled.div<{ $active?: boolean }>`
//   height: 6px;
//   width: 6px;
//   transition: ${DEFAULT_TRANSITION_STYLES('background-color')};
//   background-color: ${({ theme, $active }) =>
//     $active ? theme.gray.400 : theme.darken};
//   border-radius: 50%;
// `;

// const GalleryIndicatorDotButton = styled(BaseButton)`
//   transition: ${DEFAULT_TRANSITION_STYLES('transform')};
//   margin: 3px;
//   &:hover {
//     transform: scale(1.1);
//     ${GalleryIndicatorDot} {
//       background-color: ${({ theme }) => theme.gray.400};
//     }
//   }
// `;

// const BlockverseGalleryIndicatorsContainer = styled(Flex)`
//   position: absolute;
//   bottom: ${(p) => p.theme.padding.box}px;
//   width: fit-content;
//   gap: ${(p) => 0}px;
//   left: 0;
//   right: 0;
//   margin: auto;
//   background-color: ${({ theme }) => theme.darken};
//   padding: 1px;
//   border-radius: ${(p) => p.theme.pillBorderRadius}px;
// `;

// const BlockverseName = styled(H2)`
//   height: 2rem;
//   font-size: 1.8rem;
//   line-height: 1.8rem;
//   @media (max-width: ${(p) => p.theme.breakpoints.upToSmall}px) {
//     font-size: 1.5rem;
//     line-height: 1.5rem;
//     height: 1.8rem;
//   }
// `;

// const BlockverseIdLabel = styled(MedP)`
//   color: ${({ theme }) => theme.darken.35};
//   font-size: 12px;
//   /* transform: translateY(1px); */
//   font-weight: 500;
//   height: 12px;
// `;

// const BlockverseMomentLabel = styled(DisplayP)`
//   color: ${({ theme }) => theme.gray.600};
//   height: 0.85rem;
// `;

// const ChainsContainer = styled(FlexCenter)`
//   gap: ${(p) => p.theme.padding.innerBox}px;
//   flex-wrap: wrap;
//   max-width: ${(p) => p.theme.padding.page * 2};
// `;
// const ChainImgContainer = styled.div`
//   border-radius: ${(p) => p.theme.pillBorderRadius}px;
//   overflow: hidden;
//   height: 16px;
//   width: 16px;
//   position: relative;
// `;

// const BlockverseCarouselCard: FC<{
//   chainId: AvailableChainId;
// }> = ({ txnHash, chainId }) => {
//   const artUrl = useHiraethArtImageUrlByChainAndTxnHash(chainId, txnHash);
//   return (
//     <BlockverseCarouselImgContainer
//       as={motion.div}
//       {...USE_VARIANT_PROPS}
//       variants={GALLERY_ITEM_TRANSITION_VARIANTS}
//     >
//       {!!artUrl && (
//         <ArtPreview
//           fill
//           src={artUrl}
//           alt={`${HIRAETH_PROJECT_METADATA.name} art of ${txnHash}`}
//           unoptimized
//         />
//       )}
//     </BlockverseCarouselImgContainer>
//   );
// };

// const ArtPreview = styled(BaseLoadingImg)`
//   image-rendering: pixelated;
//   object-fit: contain;
//   display: block;
//   transition: ${DEFAULT_TRANSITION_STYLES('transform')};
// `;

// const BlockverseGalleryContainer = styled.div`
//   /* border-bottom: 1px solid ${({ theme }) => theme.darken}; */
//   border-top: 1px solid ${({ theme }) => theme.darken};
// `;

// const BlockverseGalleryLayout = styled(ExtraLargeWidthContent)`
//   padding: ${(p) => p.theme.padding.page}px ${(p) => p.theme.padding.box}px;
//   display: grid;
//   grid-template-columns: 1fr 0.6fr;
//   min-height: 720px;
//   max-height: min(1024px, 100vh);
//   @media (max-width: ${(p) => p.theme.breakpoints.upToExtraLarge}px) {
//     padding-left: ${(p) => p.theme.padding.box}px;
//     padding-right: ${(p) => p.theme.padding.box}px;
//   }
//   @media (max-width: ${(p) => p.theme.breakpoints.upToSmall}px) {
//     grid-template-columns: 1fr;
//     padding-top: ${(p) => p.theme.padding.box}px;
//     min-height: unset;
//   }
// `;

// const BlockverseCarouselCardContainer = styled(motion.div)`
//   position: absolute;
//   top: ${(p) => p.theme.padding.page * 2}px;
//   left: ${(p) => p.theme.padding.page * 2}px;
//   right: ${(p) => p.theme.padding.page * 2}px;
//   bottom: ${(p) => p.theme.padding.page * 2}px;
//   @media (max-width: ${(p) => p.theme.breakpoints.upToSmall}px) {
//     top: ${(p) => p.theme.padding.page}px;
//     left: ${(p) => p.theme.padding.page}px;
//     right: ${(p) => p.theme.padding.page}px;
//     bottom: ${(p) => p.theme.padding.page * 1.5}px;
//   }
// `;

// const BlockverseCarouselImgContainer = styled(ImgContainer)`
//   width: 100%;
//   height: 100%;
//   position: absolute;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   box-shadow: none;
//   border-radius: ${(p) => p.theme.padding.increment / 2}px;
// `;

// const BlockverseGalleryWell = styled(FlexCenter)`
//   background-color: ${({ theme }) => theme.darken.03};
//   padding: ${(p) => p.theme.padding.page}px;
//   position: relative;
//   border-radius: ${(p) => p.theme.innerBorderRadius}px;
//   transition: ${DEFAULT_TRANSITION_STYLES('background-color')};
//   &:hover {
//     background-color: ${({ theme }) => theme.darken};
//     ${ArtPreview} {
//       transform: scale(0.97);
//     }
//   }
//   @media (max-width: ${(p) => p.theme.breakpoints.upToSmall}px) {
//     aspect-ratio: unset;
//     height: 420px;
//   }
// `;

// const FeedItemDetailsContainer = styled(FlexCenterColumn)`
//   transition: ${DEFAULT_TRANSITION_STYLES('all')};
//   cursor: pointer;
//   &:hover {
//     opacity: 0.8;
//     transform: scale(0.97);
//   }
// `;

// const CtaButton = styled(AnchorActionButton)`
//   display: flex;
//   align-items: center;
//   ${DEFAULT_BOX_SHADOW};
//   background-color: ${({ theme }) => theme.blockverse};
//   color: ${({ theme }) => theme.white};
//   ${(p) => SVG_ONLY_FILL_COLOR(p.theme.white-pane.75)};
//   svg {
//     margin-left: ${(p) => p.theme.padding.increment}px;
//     height: 10px;
//   }
//   width: fit-content;
// `;

// const GALLERY_ITEM_TRANSITION_VARIANTS: Variants = {
//   initial: {
//     y: 18,
//     opacity: 0,
//   },
//   animate: {
//     y: 0,
//     opacity: 1,
//     transition: { duration: 0.24 },
//   },
//   exit: {
//     y: -18,
//     opacity: 0,
//     transition: { duration: 0.24 },
//   },
// };
