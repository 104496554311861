import { useQueryClient, type InfiniteData } from '@tanstack/react-query';
import { getQueryKey } from '@trpc/react-query';
import { produce } from 'immer';
import React, { createContext, useContext, useMemo } from 'react';
import { trpc } from '~src/clients/trpc-client';
import { QUERY_LIVENESS } from '~src/constants/query';
import { useFarcasterUserFromPrivy } from '~src/hooks/privy/useFarcasterUserFromPrivy';
import { useBatchHiraethItemCheckoutState } from '~src/pages/hiraeth/txn/hooks/useBatchHiraethItemCheckoutState';
import type { RouterOutputs } from '~src/server/trpc/routers';
import type { BlockverseObjId } from '~src/shared-apps/blockverse/types';
import type { CheckoutItemStateProps } from '~src/shared-apps/checkout/types/state';
import type { UseTRPCInfiniteQueryReturn } from '~src/types/common';
import type { FarcasterAllowedChannelId } from '../../../config/social';
import type { SocialPostForUI } from '../types/post/ui';
import {
  SocialFeedQueryModifiersProvider,
  type SocialFeedQueryModifiers,
} from './SocialFeedQueryModifiers';

type BlockverseSocialFeedContextType = {
  blockverseFeedResult?: UseTRPCInfiniteQueryReturn<
    RouterOutputs['social']['feed']['channel'],
    string | undefined
  >;
  hiraethCheckoutStates: Record<string, CheckoutItemStateProps>;
};

export const BlockverseSocialFeedContext =
  createContext<BlockverseSocialFeedContextType>({
    blockverseFeedResult: undefined,
    hiraethCheckoutStates: {},
  });

export type BlockverseSocialFeedProviderProps = {
  channelIds: FarcasterAllowedChannelId[];
};

export const useBlockverseSocialFeed = () => {
  const context = useContext(BlockverseSocialFeedContext);
  if (!context) {
    throw new Error(
      'useBlockverseSocialFeed must be used within a BlockverseSocialFeedProvider',
    );
  }
  return context;
};

export const BlockverseSocialFeedProvider = ({
  children,
  channelIds,
}: BlockverseSocialFeedProviderProps & { children: React.ReactNode }) => {
  const farcasterUser = useFarcasterUserFromPrivy();

  const blockverseFeedKey = useMemo(
    () =>
      getQueryKey(
        trpc.social.feed.channel,
        {
          channelIds,
          viewerFid: farcasterUser?.fid ?? undefined,
        },
        'infinite',
      ),
    [channelIds, farcasterUser?.fid],
  );

  const blockverseFeedResult = trpc.social.feed.channel.useInfiniteQuery(
    {
      channelIds,
      viewerFid: farcasterUser?.fid ?? undefined,
    },
    {
      // ...QUERY_LIVENESS['current-page'],
      getNextPageParam: (lastPage) => lastPage?.next.cursor,
      // refetchInterval: 10 * 1000,
    },
  );

  const hiraethCheckoutStates = useBatchHiraethItemCheckoutState(
    useMemo(() => {
      return (
        blockverseFeedResult.data?.pages.flatMap(
          (page) =>
            page?.posts
              .map((post) => post.blockverse?.id)
              .filter(
                (id) => !!id && id[0] === 'hiraeth',
              ) as BlockverseObjId<'hiraeth'>[],
        ) ?? []
      );
    }, [blockverseFeedResult.data]),
    {
      ...QUERY_LIVENESS['current-page'],
    },
  );

  const queryClient = useQueryClient();

  const blockverseFeedModifiers = useMemo((): SocialFeedQueryModifiers => {
    return {
      cancelQueries: () =>
        queryClient.cancelQueries({
          queryKey: [getQueryKey(trpc.social.feed)],
        }),
      updatePostOptimistically: (
        postId: string,
        modifier: (post: SocialPostForUI) => SocialPostForUI,
      ) => {
        queryClient.setQueryData(
          blockverseFeedKey,
          (prev: InfiniteData<RouterOutputs['social']['feed']['channel']>) => {
            return produce(prev, (draft) => {
              if (!draft) {
                return;
              }
              for (const page of draft.pages) {
                if (!page) {
                  continue;
                }
                const postIndex = page.posts.findIndex((p) => p.id === postId);
                if (postIndex !== -1) {
                  page.posts[postIndex] = modifier(page.posts[postIndex]);
                  break;
                }
              }
            });
          },
        );
      },
      invalidateFeed: () => {
        queryClient.invalidateQueries({
          queryKey: blockverseFeedKey,
        });
      },
    };
  }, [queryClient, blockverseFeedKey]);

  const state = useMemo(() => {
    return {
      blockverseFeedResult,
      hiraethCheckoutStates,
    };
  }, [blockverseFeedResult, hiraethCheckoutStates]);

  return (
    <BlockverseSocialFeedContext.Provider value={state}>
      <SocialFeedQueryModifiersProvider {...blockverseFeedModifiers}>
        {children}
      </SocialFeedQueryModifiersProvider>
    </BlockverseSocialFeedContext.Provider>
  );
};
