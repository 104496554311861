import { FC } from 'react';

import { CtaBlock } from '~src/components/block/cta';
import { ContentQuoteFooter } from '~src/components/footer/contentQuoteFooter';
import { Container, DefaultPageLayout, Spacer } from '~src/components/layout';
import { DisplayP, H4, H5 } from '~src/components/text';
import { InheritSpan } from '~src/components/text/spans';
import { FARCASTER_ALLOWED_CHANNELS } from '~src/config/social';
import { BlockverseSocialFeed } from '~src/shared-apps/social/components/feed/blockverse';
import { BlockverseSocialFeedProvider } from '~src/shared-apps/social/providers/BlockverseSocialFeed';
import { Box, Flex } from '~src/styled-system/jsx';
import { Hero } from './components/hero';

export const IndexLayout: FC = () => {
  return (
    <DefaultPageLayout>
      <BlockverseSocialFeedProvider channelIds={['crypto-history']}>
        {/* <SmallContentSpacer /> */}
        {/* <SummerIndexJumbotron /> */}
        <Hero />
        <Spacer.LargestBox />
        <Container.Sm padMobile>
          <Flex flx={'flexRowEnds'}>
            <Box>
              <H4
                color={'gray.700'}
                fontWeight={'display.normal'}
                lineHeight={'1'}
              >
                Latest history from the community
              </H4>
              <DisplayP.Default color={'gray.400'} mt={'halfIncrement'}>
                Powered by{' '}
                <InheritSpan color={'farcaster'}>Farcaster</InheritSpan>
              </DisplayP.Default>
            </Box>
            <H5
              py={'increment'}
              px={'increment'}
              bg={'darken.025'}
              rounded={'innerBox'}
              color={'gray.400'}
              fontWeight={'display.normal'}
              lineHeight={'1'}
            >
              {FARCASTER_ALLOWED_CHANNELS['crypto-history'].slug}
            </H5>
          </Flex>
          <Spacer.Box />
          <BlockverseSocialFeed />
        </Container.Sm>
      </BlockverseSocialFeedProvider>
      {/* <ContentSpacer />
      <IndexFeed />
      <LargeContentSpacer $isMobileMultiplierPadded />
      <IndexFooter />
      <SmallContentSpacer />
      <HowBlockverseWorks />
      <SmallContentSpacer />
      <BlockverseFeatures />
      <LargeContentSpacer $isMobileMultiplierPadded /> */}
      {/* <SmallWidthContent $isMobilePadded>
        <FlexCenterColumn>
          <H2
            style={{ textAlign: 'center', color: theme.black, width: '100%' }}
          >
            <Balancer>
              Expressionist art built with <BrandSpan>love</BrandSpan>
            </Balancer>
          </H2>
          <StyledSecondaryMultilineDisplayP
            style={{
              marginTop: 12,
              lineHeight: '1.1rem',
            }}
          >
            <Balancer>
              Non-fungible tokens, NFTs, aren’t all created{' '}
              <HighlightSpan color="mint">equal.</HighlightSpan>
              <br />
              <BrandStrong>Pob Studio</BrandStrong> is focused on changing the
              way people think about using tokens as an artist medium.
            </Balancer>
          </StyledSecondaryMultilineDisplayP>
          <ButtonRow>
              <PrimaryCtaButton $buttonStyle="primary">
                About Us
              </PrimaryCtaButton>
            </Link>
              <AnchorCTAButton $buttonStyle="tertiary">
                Our Vision
              </AnchorCTAButton>
            </Link>
          </ButtonRow>
        </FlexCenterColumn>
      </SmallWidthContent>
      <ContentSpacer />
      <MediumWidthContent $isMobilePadded>
        <LineBreak />
        <ContentSpacer $isMobileMultiplierPadded />
        <H2 style={{ color: theme.gray.600, textAlign: 'center' }}>
          Our work
        </H2>
        <SecondaryMultilineDisplayP
          style={{
            marginTop: 12,
            fontSize: 13,
            margin: 'auto',
            textAlign: 'center',
          }}
        >
          Generative art. Interactive worlds. Experience Ethereum.
        </SecondaryMultilineDisplayP>
        <SmallContentSpacer $isMobileMultiplierPadded />
        <WorkStandardGrid>
          <StyledProjectCard id={'london'} />
          <StyledProjectCard id={'hash'} />
          <MoreWorkCta />
        </WorkStandardGrid>
      </MediumWidthContent>
      <ContentSpacer /> */}
      <Spacer.LargeBox />
      <CtaBlock width="sm" />
      <ContentQuoteFooter>
        “I have no special talent. I am only passionately curious.” - Albert
        Einstein
      </ContentQuoteFooter>
    </DefaultPageLayout>
  );
};
